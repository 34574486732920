.site-nav {
  position: relative;
  @include breakpoint(laptop) {
    position: initial;
  }
  .site-nav-container {
    background-color: rgba(color(black), 0.9);
    display: block;
    margin: 0;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    padding: 1rem 2rem 0;
    position: absolute;
    top: 0;
    left: -2rem;
    right: -2rem;
    bottom: auto;
    min-height: 99vh;
    z-index: 2;
    @include breakpoint(laptop) {
      position: initial;
      padding: 0;
      pointer-events: initial;
      opacity: 1;
      width: 100%;
      top: auto;
      left: auto;
      right: auto;
    }
    @include transition(opacity);
    &.is-active {
      pointer-events: initial;
      opacity: 1;
    }
  }
  ul {
    display: block;
    padding: 0;
    li {
      display: block;
      position: relative;
      padding: 0;
      text-align: center;
      a {
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        line-height: 1.85rem;
        color: color(white);
        letter-spacing: 0px;
        display: block;
        position: relative;
        padding: 10px 20px;
        text-align: center;
        position: relative;
        text-decoration: none;
        outline: none;
        @include transition("color,border-color");
        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          width: 2px;
          height: 100%;
          margin: 0 auto;
          background-color: color(black);
          @include transition("color,background-color,width");
        }
        &:hover,
        &:focus,
        &.router-link-exact-active,
        &:active {
          &::after {
            background-color: color(purple);
          }
        }
        &:hover,
        &:active {
          color: color(purple-light);
        }
      }
      @include breakpoint(laptop) {
        a {
          border-bottom: 1px solid color(grey-dark);
          &:focus {
            color: color(purple-lighter);
            &::after {
              background-color: color(purple-light);
            }
          }
        }
        &:last-child {
          a {
            border-bottom: 1px solid color(black);
          }
        }
      }
    }
  }
}