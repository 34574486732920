.tag-cloud {
  ul {
    list-style-type: none;
    margin: 1rem -0.5rem;
    .tag-cloud__item {
      padding: 0 0.5rem 1rem 0.5rem;

      a {
        display: inline-block;
        background-color: color(grey-dark);
        border-bottom: 2px solid color(black);
        color: color(white);
        padding: 0.5rem 1rem;
        @include transition("color,border-color");

        &:hover,
        &:active {
          border-bottom: 2px solid color(purple);
          color: color(purple-light);
        }

        &:focus {
          border-bottom: 2px solid color(purple-light);
          color: color(purple-lighter);
        }
      }
    }
  }
}
