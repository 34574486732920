$maxInt: 12;

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 calc(var(--gutter) * 2);
  position: relative;
  width: 100%;
  &.small {
    max-width: 768px;
  }
  &.large {
    max-width: 1446px;
  }
  &.full {
    max-width: 100%;
  }
}

.mrl-auto {
  margin-right: auto;
  margin-left: auto;
}
@for $i from 0 through $maxInt {
  /* positive margins */
  &.m-#{$i} {
    margin: #{$i}em;
  }
  &.mt-#{$i} {
    margin-top: #{$i}em;
  }
  &.mb-#{$i} {
    margin-bottom: #{$i}em;
  }
  &.mtb-#{$i} {
    margin-top: #{$i}em;
    margin-bottom: #{$i}em;
  }
  &.ml-#{$i} {
    margin-left: #{$i}em;
  }
  &.mr-#{$i} {
    margin-right: #{$i}em;
  }
  &.mrl-#{$i} {
    margin-right: #{$i}em;
    margin-left: #{$i}em;
  }
  /* nagative margins */
  &.m-0-#{$i} {
    margin: -#{$i}em;
  }
  &.mt-0-#{$i} {
    margin-top: -#{$i}em;
  }
  &.mb-0-#{$i} {
    margin-bottom: -#{$i}em;
  }
  &.mtb-0-#{$i} {
    margin-top: -#{$i}em;
    margin-bottom: -#{$i}em;
  }
  &.mr-0-#{$i} {
    margin-right: -#{$i}em;
  }
  &.ml-0-#{$i} {
    margin-left: -#{$i}em;
  }
  &.mrl-0-#{$i} {
    margin-right: -#{$i}em;
    margin-left: -#{$i}em;
  }
  /* padding */
  &.p-#{$i} {
    padding: #{$i}em;
  }
  &.pt-#{$i} {
    padding-top: #{$i}em;
  }
  &.pb-#{$i} {
    padding-bottom: #{$i}em;
  }
  &.ptb-#{$i} {
    padding-top: #{$i}em;
    padding-bottom: #{$i}em;
  }
  &.pl-#{$i} {
    padding-left: #{$i}em;
  }
  &.pr-#{$i} {
    padding-right: #{$i}em;
  }
  &.prl-#{$i} {
    padding-right: #{$i}em;
    padding-left: #{$i}em;
  }
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;

  &.flex-wrap-nowrap {
    flex-wrap: nowrap;
  }

  &.flex-wrap-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  flex-direction: row;

  &.direction-row-reverse {
    flex-direction: row-reverse;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-column-reverse {
    flex-direction: column-reverse;
  }

  justify-content: flex-start;

  &.justify-content-flex-end {
    justify-content: flex-end;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.justify-content-space-around {
    justify-content: space-around;
  }

  &.justify-content-space-evenly {
    justify-content: space-evenly;
  }

  align-items: stretch;

  &.align-items-start {
    justify-content: flex-start;
  }

  &.align-items-end {
    justify-content: flex-end;
  }

  &.align-items-center {
    justify-content: center;
  }

  &.align-items-baseline {
    justify-content: baseline;
  }

  align-content: stretch;

  &.align-content-start {
    align-content: flex-start;
  }

  &.align-content-end {
    align-content: flex-end;
  }

  &.align-content-center {
    align-content: center;
  }

  &.align-content-space-between {
    align-content: space-between;
  }

  &.align-content-space-around {
    align-content: space-around;
  }

  &.align-content-space-evenly {
    align-content: space-evenly;
  }

  .is-inner {
    display: block;
    width: 100%;
  }

  &.has-inner-0 {
    margin: 0 0rem;
    .is-inner {
      margin: 0 0rem;
    }
  }

  &.has-inner {
    margin: 0 -1rem;
    .is-inner {
      margin: 0 1rem;
    }
  }

  &.has-inner-md {
    margin: 0 -1.5rem;
    .is-inner {
      margin: 0 1.5rem;
    }
  }

  &.has-inner-lg {
    margin: 0 -2rem;
    .is-inner {
      margin: 0 2rem;
    }
  }

  &.has-inner-xl {
    margin: 0 -3rem;
    .is-inner {
      margin: 0 3rem;
    }
  }
}

.flex-item {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  align-self: auto;
  flex-shrink: 1;
  flex-basis: 100%;
  order: 0;

  &.align-self-start {
    align-self: flex-start;
  }

  &.align-self-end {
    align-self: flex-end;
  }

  &.align-self-center {
    align-self: center;
  }

  &.align-self-baseline {
    align-self: baseline;
  }

  &.align-self-stretch {
    align-self: stretch;
  }
}

.fg-auto {
  flex-grow: 1;
}
@for $i from 1 through ($maxInt - 1) {
  .fg-#{$i} {
    flex-grow: $i;
  }
}

.fs-auto {
  flex-shrink: auto;
}
@for $i from 2 through ($maxInt - 2) {
  .fs-#{$i} {
    flex-shrink: $i;
  }
}

$percentages: (
  "auto": auto,
  "10": 10%,
  "16": 16.666%,
  "20": 20%,
  "25": 25%,
  "30": 33.333%,
  "33": 33.333%,
  "40": 40%,
  "50": 50%,
  "60": 60%,
  "66": 66.667%,
  "70": 66.667%,
  "75": 75%,
  "80": 80%,
  "83": 83.333%,
  "90": 90%,
  "100": 100%,
);

@each $val in $percentages {
  $key: nth($val, 1);
  $value: nth($val, 2);
  .fb-#{$key} {
    flex-basis: $value;
  }
  .w-#{$key} {
    width: $value;
  }
}

//mobiles and smaller
@include breakpoint(mobileonly) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-xs-#{$key} {
      flex-basis: $value;
    }
    .w-xs-#{$key} {
      width: $value;
    }
  }
}

//large phones and bigger
@include breakpoint(phablet) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-sm-#{$key} {
      flex-basis: $value;
    }
    .w-sm-#{$key} {
      width: $value;
    }
  }
}

//tablet and bigger
@include breakpoint(tablet) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-md-#{$key} {
      flex-basis: calc(#{$value} - var(--gutter, 0));
    }
    .w-md-#{$key} {
      width: calc(#{$value} - var(--gutter, 0));
    }
  }
}

//laptop and bigger
@include breakpoint(laptop) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-lg-#{$key} {
      flex-basis: $value;
    }
    .w-lg-#{$key} {
      width: $value;
    }
  }
}

//desktop and bigger
@include breakpoint(desktop) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-xl-#{$key} {
      flex-basis: $value;
    }
    .w-xl-#{$key} {
      width: $value;
    }
  }
}

//desktop and bigger
@include breakpoint(desktop-large) {
  @each $val in $percentages {
    $key: nth($val, 1);
    $value: nth($val, 2);
    .fb-xxl-#{$key} {
      flex-basis: $value;
    }
    .w-xxl-#{$key} {
      width: $value;
    }
  }
}

//mobiles and smaller
@include breakpoint(mobileonly) {
  @for $i from 0 through $maxInt {
    .order-xs-#{$i} {
      order: $i;
    }
  }
}

//large phones and bigger
@include breakpoint(phablet) {
  @for $i from 0 through $maxInt {
    .order-sm-#{$i} {
      order: $i;
    }
  }
}

//tablet and bigger
@include breakpoint(tablet) {
  @for $i from 0 through $maxInt {
    .order-md-#{$i} {
      order: $i;
    }
  }
}

//laptop and bigger
@include breakpoint(laptop) {
  @for $i from 0 through $maxInt {
    .order-lg-#{$i} {
      order: $i;
    }
  }
}

//desktop and bigger
@include breakpoint(desktop) {
  @for $i from 0 through $maxInt {
    .order-xl-#{$i} {
      order: $i;
    }
  }
}

//desktop and bigger
@include breakpoint(desktop-large) {
  @for $i from 0 through $maxInt {
    .order-xxl-#{$i} {
      order: $i;
    }
  }
}

//mobiles and smaller
@include breakpoint(mobileonly) {
  .hidden-xs {
    display: none;
  }
  .rm-p-xs {
    padding: 0;
  }
}

//large phones and bigger
@include breakpoint(phablet) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: initial;
  }
  .rm-p-sm {
    padding: 0;
  }
}

//tablet and bigger
@include breakpoint(tablet) {
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: initial;
  }
  .rm-p-md {
    padding: 0;
  }
}

//laptop and bigger
@include breakpoint(laptop) {
  .hidden-lg {
    display: none;
  }
  .visible-lg {
    display: initial;
  }
  .rm-p-lg {
    padding: 0;
  }
}

//desktop and bigger
@include breakpoint(desktop) {
  .hidden-xl {
    display: none;
  }
  .visible-xl {
    display: initial;
  }
}

.left-content {
  text-align: left;
}

.right-content {
  text-align: right;
}

.justify-content {
  text-align: justify;
}

.center-content {
  text-align: center;
}
@include breakpoint(tablet) {
  .left-content-md {
    text-align: left;
  }
  .right-content-md {
    text-align: right;
  }
  .justify-content-md {
    text-align: justify;
  }
  .center-content-md {
    text-align: center;
  }
}

.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.pull-none {
  float: none;
}
//phablet and bigger
@include breakpoint(phablet) {
  .pull-right-sm {
    float: right;
  }
  .pull-left-sm {
    float: left;
  }
  .pull-none-sm {
    float: none;
  }
}
//tablet and bigger
@include breakpoint(tablet) {
  .pull-right-md {
    float: right;
  }
  .pull-left-md {
    float: left;
  }
  .pull-none-md {
    float: none;
  }
}

.d-block {
  display: block;
}

.d-iblock {
  display: inline-block;
}

.d-inline {
  display: inline;
}

.content {
  display: block;
  padding: calc(var(--gutter) * 2);
  width: 100%;
}
