.copyright {
  width: 100%;
  display: block;
  padding: 0.4rem 0.8rem 1rem;
  line-height: 1.1rem;
  font-size: 0.8rem;
  text-align: center;
  color: color(grey);
  @include breakpoint(laptop) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}